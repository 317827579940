import React, { useCallback, useEffect, useState } from 'react';
import logo from "./img/logo.png";
import './App.css';
import { AptosAccount, AptosAccountObject,AptosClient, BCS, CoinClient, HexString, MaybeHexString, TokenClient, TransactionBuilder, TxnBuilderTypes, Types } from 'aptos';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { AptosWalletName,BloctoWalletName,PontemWalletName,RiseWalletName, useWallet, MartianWalletName , NetworkInfo, WalletAdapterNetwork, AccountKeys, Wallet} from "@manahippo/aptos-wallet-adapter"
import { ListCardUnstaked } from './components/ListCardUnstaked';
import { ListCardStaked } from './components/ListCardStaked';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Timestamp from 'react-timestamp';
import express from 'express';


const aptosWeb3 = require('@martiandao/aptos-web3-bip44.js');


type StakedNFT = {
  staked_nft_names: [];
};


 function Game() {
  

  const firebaseConfig = {
    apiKey: "AIzaSyAPYK7aHxSE0pzC9dHGuzPNf7zcsiRwF-U",
    authDomain: "spacefinger-df45b.firebaseapp.com",
    projectId: "spacefinger-df45b",
    storageBucket: "spacefinger-df45b.appspot.com",
    messagingSenderId: "382093829067",
    appId: "1:382093829067:web:871aecccd807beb3bf8c4e",
    measurementId: "G-H09T9LKYR5"
  };
  
  
  
  
  
  const NODE_URL = "https://fullnode.mainnet.aptoslabs.com";
  const FAUCET_URL = "https://faucet.devnet.aptoslabs.com";
  const client = new AptosClient(NODE_URL);
  const coinClient = new CoinClient(client); 
  const tokenClient = new TokenClient(client); 
  const walletClient = new aptosWeb3.WalletClient(NODE_URL, FAUCET_URL);

  
  const [open, setOpen] = React.useState(false);
  const { connect, disconnect, connected,signAndSubmitTransaction,account } = useWallet();
  

  const [currentBid,SetCurrentBid] = useState<String>("")
  const [currentBidder,SetCurrentBidder] = useState<String>("")
  const [auction_collection,SetAuctionCollection] = useState<String>("")
  const [auction_token,SetAuctionToken] = useState<String>("")
  const [auction_creator,SetAuctionCreator] = useState<String>("")
  const [placedBid,SetPlacedBid] = useState<string>("")
  const [tokenImage,SetTokenImage] = useState<string>("")
  const [endTime,SetEndTime] = useState<number>(0)
  const [nowTime,SetNowTime] = useState<number>(0)

  const collection = "Circle Pass";
  const tokenname = "Circle Pass #8668";
  const creator = "0x24d2394d0fcbd93639c5932de14c778bd78d6bddde1dbb525dc7e7f83ab18456";
  

 

 useEffect(() => {
  
    
  setOpen(false)
  console.log(account)
  
  //setIsConnected(true)
  
}, [connected]);
  
  
  
// Establish connection to the wallet
async function ConnectWallet() {

  setOpen(true)

}



async function GetAuctionInfo() {
  const auction_resource = await client.getAccountResource("0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57","0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57::marketplace::AuctionData")
 
  if((auction_resource.data as any).auction_items.length != 0)
  {
      const auction_item = (auction_resource.data as any).auction_items[0]
      const currentBid = auction_item.current_bid;
      const currentBidder = auction_item.current_bidder;
      const collectionName = auction_item.locked_token.vec[0].id.token_data_id.collection;
      const tokenName = auction_item.locked_token.vec[0].id.token_data_id.name;
      const creator = auction_item.locked_token.vec[0].id.token_data_id.creator
      
      var bid_amount = Number(currentBid)
      bid_amount = bid_amount/100000000
      
      const startTime = auction_item.start_time;
      const duration = auction_item.duration;

      const endTime = Number(startTime) + Number(duration)


      const timestamp = Date.now();
      
      
      
      
      
      const token = await tokenClient.getTokenData(creator,collectionName,tokenName)
      
      const response = await fetch("https://gateway.pinata.cloud/ipfs/"+token.uri.split("/")[2]+"/"+token.uri.split("/")[3])
        
        //console.log("https://gateway.pinata.cloud/ipfs/"+unstakableToken.uri.split("/")[2]+"/"+unstakableToken.uri.split("/")[3])
        const metadataJson = await response.json()
        console.log(metadataJson)   

      SetTokenImage("https://gateway.pinata.cloud/ipfs/bafybeifqmy4xcwfbfe6kairdt4dg2bal3b5lcl577ipnznxtvavqi324si/1.png")
      SetCurrentBid(String(bid_amount))
      SetCurrentBidder(currentBidder)
      SetAuctionCollection(collectionName)
      SetAuctionToken(tokenName)
      SetEndTime(endTime)
      SetNowTime(timestamp)
      SetAuctionCreator(creator)
  }
  else{
    SetCurrentBid("")
      SetCurrentBidder("")
      SetAuctionCollection("")
      SetAuctionToken("")
      SetAuctionCreator("")
      SetEndTime(0)
  }

  setTimeout(function() {
   GetAuctionInfo()
  }, 1000);
}

useEffect(() => {
  
 
  
 
  if (account?.address) {
    
    GetAuctionInfo()
  }
}, [account]);
useEffect(() => {
  
    GetAuctionInfo()
  
}, []);
//setInterval(GetAuctionInfo, 30000);

async function InitAuctionHouse() {
  

  
  
  


  const transaction = {
    arguments: [],
    function: '0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57::marketplace::initialize_auction',
    type: 'entry_function_payload',
    type_arguments: ["0x40c2bd88c1313795ff0c6202aa58d8a6545ceaca1dafa5208c7586fb0f9d6b96::bananacoin::BananaToken"],
  };
  

    
    
    const result = await  signAndSubmitTransaction(transaction)

    
    
}
async function CreateAuction() {
    
  const transaction = {
    arguments: [creator,collection,tokenname,100000,86400 * 2 ],
    function: '0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57::marketplace::create_auction',
    type: 'entry_function_payload',
    type_arguments: ["0x40c2bd88c1313795ff0c6202aa58d8a6545ceaca1dafa5208c7586fb0f9d6b96::bananacoin::BananaToken"],
  };
  

    
    
    const result = await  signAndSubmitTransaction(transaction)
  
}
async function BidAuction(bid:string) {
    
  const transaction = {
    arguments: [auction_creator,auction_collection,auction_token,Number(bid) * 100000000],
    function: '0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57::marketplace::bid',
    type: 'entry_function_payload',
    type_arguments: ["0x40c2bd88c1313795ff0c6202aa58d8a6545ceaca1dafa5208c7586fb0f9d6b96::bananacoin::BananaToken"],
  };
  

    
    
    const result = await  signAndSubmitTransaction(transaction)
  
}
async function ClaimAuctionToken() {
    
  const transaction = {
    arguments: [auction_creator,auction_collection,auction_token],
    function: '0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57::marketplace::claim_token',
    type: 'entry_function_payload',
    type_arguments: ["0x40c2bd88c1313795ff0c6202aa58d8a6545ceaca1dafa5208c7586fb0f9d6b96::bananacoin::BananaToken"],
  };
  

    
    
    const result = await  signAndSubmitTransaction(transaction)
  
}
async function ClaimCoins() {
    
  const transaction = {
    arguments: ["0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57",collection,tokenname],
    function: '0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57::marketplace::claim_coins',
    type: 'entry_function_payload',
    type_arguments: ["0x40c2bd88c1313795ff0c6202aa58d8a6545ceaca1dafa5208c7586fb0f9d6b96::bananacoin::BananaToken"],
  };
  

    
    
    const result = await  signAndSubmitTransaction(transaction)
  
}






const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  SetPlacedBid(event.target.value);
};

  return (
    <div style={{textAlign:"center",backgroundColor:"black",backgroundSize:"cover",width:"100vw",height:"100vh",justifyContent:"center"}}>
     
      
      
    
      
      

      <div style={{display:"flex",flexDirection:"row",width:"100%",backgroundColor:"black"}}>
                      
                      <div style={{display:"flex",flexDirection:"row",textAlign:"center",width:"100vw",height:"auto",backgroundColor:"black",marginTop:"2vw"}}>

                      <Avatar src={logo} style={{width:"6vw",height:"auto",marginLeft:"2vw"}}></Avatar>
                      <h1 style={{color:"white",marginLeft:"2vw",fontFamily:"VT323",fontSize:"2vw"}}>Apetos Apes</h1>
                      
                      </div>
                      <div style={{width:"100vw",height:"auto",backgroundColor:"black",justifyContent:"end",textAlign:"end"}}>

                      <Button style={{fontFamily:"VT323",marginTop:"4vw",marginRight:"5vw",fontSize:"1vw",backgroundColor:"rgb(35, 56, 77,1)"}} variant="contained" onClick={ConnectWallet} disabled={connected}>Connect Wallet</Button>
                      
                      </div>
      </div>
      

      <div style={{textAlign:"center",width:"100vw"}}>

          <Box component={"div"}  sx={{width:"100vw",height:"auto",textAlign:"center"}}>

              <Box component={"div"}  sx={{width:"60vw",height:"auto",backgroundColor:"#75408f",borderRadius:"10%",background:"rgb(35, 56, 77,1)",marginLeft:"20vw",marginTop:"10vw"}}>

              <Box component={"div"}  sx={{display:"flex",flexDirection:"row",textAlign:"center",alignItems:"center",width:"80vw",height:"20vw",marginLeft:"2vw"}}>

                      <Box component={"img"} src={tokenImage} sx={{width:"16vw",height:"auto",borderRadius:"10%"}}></Box>
                      <Box component={"div"} sx={{display:"flex",flexDirection:"column",textAlign:"start",alignItems:"start",width:"80vw",height:"auto",marginLeft:"1vw"}} >
                      <h3 style={{color:"white",fontWeight:"bold",fontFamily:"VT323",fontSize:"1.4vw"}}>Collection Name: {auction_collection}</h3>
                      <h3 style={{color:"white",fontWeight:"bold",fontFamily:"VT323",fontSize:"1.4vw"}}>Token Name: {auction_token}</h3>
                      <h3 style={{color:"white",fontWeight:"bold",fontFamily:"VT323",fontSize:"1.4vw"}}>Current Bidder : {currentBidder.slice(0,4)}....{currentBidder.slice(-4)}</h3>
                      <p style={{color:"white",fontFamily:"VT323",fontSize:"1.2vw"}}>Current Bid : {currentBid} $BNN</p>
                      <div style={{display:"flex",flexDirection:"row"}}>
                      
                      <TextField sx={{width:"7vw",height:"auto",marginRight:"2vw",fontSize:"1vw",fontFamily:"VT323",color:"white",backgroundColor:"white"}} color="secondary" size="small" onChange={handleChange} value={placedBid} label="Your bid here"  variant="outlined" />
                      {(auction_token!=""  || nowTime<endTime) && (
              
                        <Button color="secondary" style={{fontSize:"1vw",height:"auto",fontFamily:"VT323",backgroundColor:"rgba(33, 55, 53)"}} variant="contained" onClick={()=>BidAuction(placedBid)} disabled={!connected && (auction_token==""  || nowTime>endTime)} >Place bid</Button>
                      )}
                      {auction_token!=""  && (nowTime>endTime) && (String(account?.address) == currentBidder) &&(
              
                        <Button color="secondary" style={{fontSize:"1vw",height:"auto",fontFamily:"VT323",backgroundColor:"rgba(33, 55, 53)",marginLeft:"2vw"}} variant="contained" onClick={ClaimAuctionToken} disabled={ ( nowTime<endTime)} >Claim</Button>
                      )}
                     
                      <h1 style={{color:"white",marginLeft:"2vw",fontSize:"1vw",fontFamily:"VT323"}}>Ends in:</h1>
                      <h1 style={{color:"white",marginLeft:"1vw",fontSize:"1vw",fontFamily:"VT323"}}><Timestamp relative date={endTime} /></h1>
                      </div>
                     
                      
                      </Box>

              </Box>

              

              </Box>

              

          </Box>

          </div>

      
      <Modal
                  open={open}
                  onClose={ConnectWallet}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{width:"20vw",color:"white",borderRadius:"10%",justifyContent:"center",textAlign:"center",backgroundColor:"rgba(33, 66, 53)",alignContent:"center",position:"absolute",top:"40%",left:"40%",alignItems:"center"}}>
                   <Button onClick={() =>connect(MartianWalletName)} variant="contained" sx={{width:"15vw",marginTop:"1vw",fontFamily:"VT323",fontSize:"1vw",backgroundColor:"rgb(35, 56, 77,1)"}} >Martian</Button>
                   <Button onClick={() =>connect(AptosWalletName)} variant="contained" sx={{width:"15vw",marginTop:"1vw",fontFamily:"VT323",fontSize:"1vw",backgroundColor:"rgb(35, 56, 77,1)"}} >Petra</Button>
                   <Button onClick={() =>connect(RiseWalletName)} variant="contained" sx={{width:"15vw",marginTop:"1vw",fontFamily:"VT323",fontSize:"1vw",marginBottom:"1vw",backgroundColor:"rgb(35, 56, 77,1)"}} >Rise</Button>
                   <Button onClick={() =>connect(PontemWalletName)} variant="contained" sx={{width:"15vw",marginTop:"0vw",fontFamily:"VT323",fontSize:"1vw",marginBottom:"1vw",backgroundColor:"rgb(35, 56, 77,1)"}} >Pontem</Button>
                   <Button onClick={() =>connect(BloctoWalletName)} variant="contained" sx={{width:"15vw",marginTop:"0vw",fontFamily:"VT323",fontSize:"1vw",marginBottom:"1vw",backgroundColor:"rgb(35, 56, 77,1)"}} >Blocto</Button>
                  </Box>
                </Modal>
      
      
    
        
      
    </div>
    
  );
}

export default Game;
